import axios from "axios";
import ModalDialog from "../components/ModalDialog/ModalDialog";

const apiUrl = process.env.REACT_APP_SERVICE_API;

const getOrderData = async (orderId, setValues) => {
  try {
    const response = await axios.get(`${apiUrl}/get-order-data`, {
      params: { orderId: orderId },
    });
    console.log({ ...response.data.landlord, ...response.data.tenant });
    setValues({ ...response.data.landlord, ...response.data.tenant });
  } catch (error) {
    ModalDialog("Ошибка формирования платежа", error.message, "error");
  }
};

export default getOrderData;
