import axios from "axios";

const apiUrl = process.env.REACT_APP_SERVICE_API;

const checkUser = async (setUserData) => {
  try {
    const response = await axios.get(`${apiUrl}/checkuser`, {
      withCredentials: true,
    }); // Ваш API для проверки статуса
    setUserData(response.data.updateUserData);
  } catch (error) {
    console.error("Ошибка связи с сервером:", error);
  }
};

export default checkUser;
