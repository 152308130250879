import React from "react";
import Box from "@mui/material/Box";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import { Container, createTheme } from "@mui/material";
import { Contractors } from "../contractor.data.js";

const theme = createTheme();

const TypeSelect = ({ selectedContract, onContractChange }) => {
  const handleChange = (event) => {
    onContractChange(event.target.value);
  };

  return (
    <div>
      <Container
        color="inherit"
        sx={{
          marginTop: theme.spacing(1),
          display: "flex",
          flexDirection: "row",
          minWidth: "sm",
          gap: theme.spacing(3),
        }}
      >
        <Box
          sx={{
            flex: 1,
            paddingLeft: theme.spacing(10),
            paddingRight: theme.spacing(10),
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControl fullWidth>
            <InputLabel>Выберите тип документа</InputLabel>
            <Select
              value={selectedContract}
              label="Выберите тип документа"
              onChange={handleChange}
            >
              {Contractors.map((contract) => (
                <MenuItem key={contract.id} value={contract.id}>
                  {contract.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Container>
    </div>
  );
};

export default TypeSelect;
