import ReactInputMask from "react-input-mask";
import { TextField, Box } from "@mui/material";
import React, { useEffect } from "react";
import style from "./Fields.module.css";
import LightToolTip from "../LightToolTip/LightToolTip.jsx";

const NumberField = (props) => {
  const {
    id,
    numberFormat,
    value,
    referenceValue,
    defaultValue,
    inputMode,
    error,
    label,
    setValue,
    setError,
    tooltip,
    required,
  } = props;
  useEffect(() => {
    if (defaultValue) {
      setValue((prevValues) => ({
        ...prevValues,
        [id]: defaultValue,
      }));
      /*       setError((prevErrors) => ({
        ...prevErrors,
        [id]: defaultValue === "",
      })); */
    }
  }, [defaultValue]);

  const handleChange = (e) => {
    const value = e.target.value.replace(/_/g, "");
    if (referenceValue) {
      setValue((prevValues) => {
        if (prevValues[referenceValue] !== "Без залога") {
          return {
            ...prevValues,
            [referenceValue]: value,
          };
        } else {
          return {
            ...prevValues,
          };
        }
      });
    }
    /*       setError((prevErrors) => ({
        ...prevErrors,
        [referenceValue]: value === "",
      })); */
    setValue((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
    /*     setError((prevErrors) => ({
      ...prevErrors,
      [id]: value === "",
    })); */
  };

  return (
    <Box className={style.boxItem}>
      <ReactInputMask
        mask={"9".repeat(numberFormat)} // Шаблон для паспорта с пробелом
        maskChar="_" // Подчеркивание для пустых символов
        value={value || ""}
        onChange={handleChange}
        {...(required && {
          error: !!error,
          helperText: !!error ? "Заполните поле" : "",
        })}
        fullWidth
      >
        {(inputProps) => (
          <TextField
            {...inputProps}
            label={label}
            variant="outlined"
            inputMode={inputMode}
            placeholder={"_".repeat(numberFormat)} // Плейсхолдер для начального состояния
          />
        )}
      </ReactInputMask>
      {tooltip && <LightToolTip tooltip={tooltip} />}
    </Box>
  );
};

export default React.memo(NumberField);
