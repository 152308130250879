import React, { useLayoutEffect, useRef } from "react";
import * as VKID from "@vkid/sdk";
import ModalDialog from "../ModalDialog/ModalDialog";
import generatePKCE from "../../service/generatePKCE";

const VkOneButton = () => {
  const containerRef = useRef(null);

  const vkidOnError = (error) => {
    <ModalDialog header="Ошибка авторизации" content={error} type="error" />;
  }; 

  useLayoutEffect(() => {
    generatePKCE()
      .then((data) => {
        // Инициализация конфигурации
        VKID.Config.init({
          app: data.APP_ID_VK,
          redirectUrl: data.REDIRECT_URL,
          state: data.state,
          codeChallenge: data.codeChallenge,
          scope: data.SCOPE_VK,
        });

        const oneTap = new VKID.OneTap();

        oneTap
          .render({
            container: containerRef.current,
          })
          .on(VKID.WidgetEvents.ERROR, vkidOnError);

        return () => {
          oneTap.close();
        };
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return <div ref={containerRef} />; // Контейнер для виджета
};

export default VkOneButton;
