import { Button, Box, Backdrop, CircularProgress } from "@mui/material";
import styles from "./CreateContract.module.css";

const CreateContract = ({ handleSubmitValues, loading }) => {
  return (
    <>
      <Button variant="outlined" onClick={handleSubmitValues}>
        Сформировать договор
      </Button>
      {/* Backdrop для размытия */}
      <Backdrop open={loading} style={{ zIndex: 9999, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CreateContract;
