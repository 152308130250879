import { TextField, Box } from "@mui/material";
import ReactInputMask from "react-input-mask";
import style from "./Fields.module.css";
import LightToolTip from "../LightToolTip/LightToolTip.jsx";
import React from "react";

const Phone = (props) => {
  const {
    id,
    value,
    inputMode,
    error,
    label,
    setValue,
    setError,
    tooltip,
    required,
  } = props;
  const handleChange = (e) => {
    const value = e.target.value.replace(/_/g, "");
    setValue((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
/*     setError((prevErrors) => ({
      ...prevErrors,
      [id]: value === "",
    })); */
  };

  return (
    <Box className={style.boxItem}>
      <ReactInputMask
        mask="+7(999)999-99-99" // Шаблон для паспорта с пробелом
        maskChar="_" // Подчеркивание для пустых символов
        value={value || ""}
        onChange={handleChange}
        {...(required && {
          error: !!error,
          helperText: !!error ? "Заполните поле" : "",
        })}
        fullWidth
      >
        {(inputProps) => (
          <TextField
            {...inputProps}
            label={label}
            variant="outlined"
            inputMode={inputMode}
            placeholder="____ _______" // Плейсхолдер для начального состояния
          />
        )}
      </ReactInputMask>
      {tooltip && <LightToolTip tooltip={tooltip} />}
    </Box>
  );
};

export default React.memo(Phone);
