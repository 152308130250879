import axios from "axios";

const apiUrl = process.env.REACT_APP_SERVICE_API;

const saveSignature = async (imageData) => {
  try {
    const response = await axios.post(
      `${apiUrl}/save-signature`,
      {
        image: imageData,
      },
      {
        headers: {
          "Content-Type": "application/json", // Указываем, что передаем JSON
        },
      }
    );

    if (response.status === 200) {
      console.log("Подпись успешно сохранена на сервере");
      // Обработать успешный ответ от сервера
    }
  } catch (error) {
    console.error("Ошибка при сохранении подписи:", error);
  }
};

export default saveSignature;
