import React, { useEffect } from "react";
import TextFieldAll from "./TextFieldAll.jsx";
import { Box } from "@mui/material";
import LightToolTip from "../LightToolTip/LightToolTip.jsx";
import style from "./Fields.module.css";

const LocationComponent = (props) => {
  const {
    id,
    label,
    value,
    inputMode,
    error,
    loadingInput,
    setValue,
    setError,
    tooltip,
    required,
  } = props;
  useEffect(() => {
    // Проверяем, поддерживает ли браузер геолокацию
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // Используем API для обратного геокодирования
          fetch(
            `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=ru`
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.city) {
                setValue((prevValues) => ({
                  ...prevValues,
                  [id]: data.city,
                }));
/*                 setError((prevErrors) => ({
                  ...prevErrors,
                  [id]: data.city === "",
                })); */
              }
            })
            .catch((err) => console.error(err));
        },
        (error) => {
          console.error("Ошибка получения местоположения:", error);
        }
      );
    } else {
      console.error("Геолокация не поддерживается в этом браузере.");
    }
  }, []);

  return (
    <Box className={style.boxItem}>
      <TextFieldAll
        id={id}
        label={label}
        value={value}
        inputMode={inputMode}
        loadingInput={loadingInput}
        setValue={setValue}
        {...(required && {
          error: !!error,
          helperText: !!error ? "Заполните поле" : "",
        })}
      />
      {tooltip && <LightToolTip tooltip={tooltip} />}
    </Box>
  );
};

export default React.memo(LocationComponent);