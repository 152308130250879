import { useState } from "react";
import { Tooltip, tooltipClasses, IconButton } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import style from "./LightToolTip.module.css";
import { styled } from "@mui/material/styles";

const LightToolTip = (props) => {
  const { tooltip } = props;
  const [open, setOpen] = useState(false);

  const handleTouchStart = (event, open) => {
    setOpen(open);
  };

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const ToolTipStyled = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      open={open}
      classes={{ popper: className }}
      arrow
      enterTouchDelay={0}
      leaveTouchDelay={60000}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      //backgroundColor: theme.palette.background.default,
      //color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: 16,
      maxWidth: "75vw",
    },
  }));

  return (
    <ToolTipStyled title={tooltip}>
      <IconButton
        className={style.iconButton}
        onClick={(e) => handleTouchStart(e, !open)}
         onMouseLeave={handleMouseLeave} // добавлено для настольных устройств
        onMouseEnter={handleMouseEnter} // добавлено для настольных устройств /*  */
      >
        <ReportProblemIcon className={style.reportIconButton} />
      </IconButton>
    </ToolTipStyled>
  );
};

export default LightToolTip;
