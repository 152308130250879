import axios from "axios";

const API_KEY = "f421839f81eb0b59834ada0ed2f458282a751ec2";
const apiUrl = process.env.REACT_APP_SERVICE_API;

const getCadastralNumber = async (address) => {
  try {
    const response = await axios.get(`${apiUrl}/api/rosreestr`, {
      params: { address: address },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка получения данных:", error);
  }
};

const fetchSuggestions = async (query, setSuggestions) => {
  if (query.length < 3) {
    setSuggestions([]);
    return;
  }

  try {
    const response = await axios.post(
      "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
      { query },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${API_KEY}`,
        },
      }
    );

    setSuggestions(response.data.suggestions.map((item) => item.value));
    //API Яндекс
    /* const response = await axios.get("http://localhost:4200/api/suggest", {
      params: { address: query },
    });
    console.log (JSON.stringify(response.data.results));
     setSuggestions(
      response.data.results.map(
        (item) => item.title?.text + ", " + item.subtitle?.text
      )
    ); // Достаем только текстовые значения
    //setCadastr(response.data.suggestions.map((item) => item)) */
  } catch (error) {
    console.error("Ошибка получения данных:", error);
  }
};

const fetchAddressCode = async (query, setAddressCode) => {
  try {
    const response = await axios.post(
      "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit",
      { query },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${API_KEY}`,
        },
      }
    );

    setAddressCode(
      response.data.suggestions.map((item) => item.data.code + " " + item.value)
    );
    //API Яндекс
    /* const response = await axios.get("http://localhost:4200/api/suggest", {
      params: { address: query },
    });
    console.log (JSON.stringify(response.data.results));
     setSuggestions(
      response.data.results.map(
        (item) => item.title?.text + ", " + item.subtitle?.text
      )
    ); // Достаем только текстовые значения
    //setCadastr(response.data.suggestions.map((item) => item)) */
  } catch (error) {
    console.error("Ошибка получения данных:", error);
  }
};

export { getCadastralNumber, fetchSuggestions, fetchAddressCode };
