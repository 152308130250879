import axios from "axios";

const apiUrl = process.env.REACT_APP_GPT_API;

const requestGPT = async (message) => {
  try {
    const response = await axios.post(
      `${apiUrl}/get-request`,
      {
        message,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const resGpt = response.data ? response.data : "Ошибка получения ответа";
    return resGpt;
  } catch (error) {
    // Проверяем, есть ли ответ от сервера
    if (error.response) {
      // Сервер ответил с кодом ошибки
      console.error("Ошибка статус:", error.response.status);
      console.error("Ошибка данные:", error.response.data);
      // Пример обработки данных об ошибке
    } else if (error.request) {
      // Запрос был сделан, но ответа не было
      console.error("Ошибка запрос:", error.request);
    } else {
      // Что-то пошло не так при настройке запроса
      console.error("Ошибка сообщение:", error.message);
    }
  }
};

export default requestGPT;
