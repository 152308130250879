import Swal from "sweetalert2"; // Основные стили SweetAlert2
import "@sweetalert2/theme-material-ui/material-ui.css";
//import "@sweetalert2/theme-dark/dark.css";

const ModalDialog = (header, content, type) => {
  Swal.fire({
    title: header,
    text: content,
    icon: type,
    showCloseButton: true,
  });
};

export default ModalDialog;
