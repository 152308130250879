import axios from "axios";
import ModalDialog from "../components/ModalDialog/ModalDialog";

const apiUrl = process.env.REACT_APP_SERVICE_API;

const generateDoc = async (data, setUrlDoc, setOrderId) => {
  try {
    const response = await axios.post(`${apiUrl}/generate-doc`, {
      data: data,
    });

    const { document, orderId } = response.data;
    setUrlDoc(document);
    setOrderId(orderId);
    return orderId;
  } catch (error) {
    ModalDialog("Ошибка при формировании документа", error.message, "error");
  }
};

export default generateDoc;
