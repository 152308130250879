import { TextField, Box } from "@mui/material";
import React, { useEffect } from "react";
import LightToolTip from "../LightToolTip/LightToolTip.jsx";
import style from "./Fields.module.css";

const DateField = (props) => {
  const {
    id,
    label,
    typeInput,
    value,
    defaultValue,
    inputMode,
    error,
    setValue,
    setError,
    tooltip,
    required,
  } = props;

  const handleChange = (e) => {
    const value = e.target.value;
    setValue((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
    setError((prevErrors) => ({
      ...prevErrors,
      [id]: value === "",
    }));
  };

  useEffect(() => {
    if (defaultValue) {
      setValue((prevValues) => ({
        ...prevValues,
        [id]: getCurrentDate(),
      }));
/*       setError((prevErrors) => ({
        ...prevErrors,
        [id]: getCurrentDate() === "",
      })); */
    }
  }, [defaultValue]);

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();

    return `${year}-${month}-${day}`;
  };

  return (
    <Box className={style.boxItem}>
      <TextField
        label={label}
        type={typeInput}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={value || ""}
        inputMode={inputMode}
        onChange={handleChange}
        {...(required && {
          error: !!error,
          helperText: !!error ? "Заполните поле" : "",
        })}
        fullWidth
      />
      {tooltip && <LightToolTip tooltip={tooltip} />}
    </Box>
  );
};

export default React.memo(DateField);
