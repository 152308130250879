import React, { useEffect, useState } from "react";
import checkPaymentStatus from "../service/checkPaymentStatus";
import { Box, Link, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as VKID from "@vkid/sdk";

const Logon = ({ setUserData }) => {
  const navigate = useNavigate();

  /* l. */

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("id")) {
      setUserData({
        isAuthentificated: true,
        id: params.get("id"),
        avatar: params.get("avatar"),
        first_name: params.get("first_name"),
        last_name: params.get("last_name"),
        email: params.get("email"),
      });
    }
  }, []);

  navigate("/");
};

export default Logon;
