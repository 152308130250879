import { Autocomplete, TextField, Checkbox, Box } from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import style from "./Fields.module.css";
import LightToolTip from "../LightToolTip/LightToolTip.jsx";
import React, { useEffect } from "react";

const SelectCheckBoxes = (props) => {
  const {
    id,
    data,
    value,
    label,
    error,
    setValue,
    setError,
    tooltip,
    required,
  } = props;

  useEffect(() => {
    setValue((prevValues) => ({
      ...prevValues,
      [id]: [{ id: "No", title: "отсутствуют" }],
    }));
  }, [setValue]);

  const handleChange = (e, newValue) => {
    const value = newValue;
    if (value.length > 0) {
      const updatedValue = value.filter((item) => item.id !== "No");
      setValue((prevValues) => ({
        ...prevValues,
        [id]: updatedValue,
      }));
    } else {
      setValue((prevValues) => ({
        ...prevValues,
        [id]: [{ id: "No", title: "отсутствуют" }],
      }));
    }
    /*     setError((prevErrors) => ({
      ...prevErrors,
      [id]: value === "",
    })); */
  };

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  return (
    <Box className={style.boxItem}>
      <Autocomplete
        multiple
        fullWidth
        options={data}
        disableCloseOnSelect
        getOptionLabel={(data) => data.title}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.title}
          </li>
        )}
        value={value || []}
        onChange={(event, newValue) => handleChange(event, newValue)}
        //isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth
            {...(required && {
              error: !!error,
              helperText: !!error ? "Заполните поле" : "",
            })}
            inputProps={{
              ...params.inputProps,
              readOnly: true, // Отключаем отображение клавиатуры
            }}
          />
        )}
      />
      {tooltip && <LightToolTip tooltip={tooltip} />}
    </Box>
  );
};

export default React.memo(SelectCheckBoxes);
