import { TextField, Box } from "@mui/material";
import { useState } from "react";
import ReactInputMask from "react-input-mask";
import style from "./Fields.module.css";
import LightToolTip from "../LightToolTip/LightToolTip.jsx";
import React from "react";

const Passport = (props) => {
  const {
    id,
    value,
    inputMode,
    error,
    label,
    setValue,
    setError,
    tooltip,
    required,
  } = props;

  const handleChange = (e) => {
    const trgValue = e.target.value.replace(/_/g, "");
    if (trgValue !== value) {
      setValue((prevValues) => ({
        ...prevValues,
        [id]: trgValue,
      }));
/*       setError((prevErrors) => ({
        ...prevErrors,
        [id]: trgValue === "",
      })); */
    }
  };

  return (
    <Box className={style.boxItem}>
      <ReactInputMask
        mask="9999 999999" // Шаблон для паспорта с пробелом
        maskChar="_" // Подчеркивание для пустых символов
        value={value || ""}
        onChange={handleChange}
        {...(required && {
          error: !!error,
          helperText: !!error ? "Заполните поле" : "",
        })}
        label={label}
        fullWidth
        inputMode={inputMode}
      >
        {(inputProps) => (
          <TextField
            {...inputProps}
            variant="outlined"
            inputMode={inputMode}
            placeholder="____ _______" // Плейсхолдер для начального состояния
          />
        )}
      </ReactInputMask>
      {tooltip && <LightToolTip tooltip={tooltip} />}
    </Box>
  );
};

export default React.memo(Passport);
