import React, { useState, useRef } from "react";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Typography,
  Tooltip,
  Avatar,
} from "@mui/material";
import styles from "./ConHeader.module.css";
import PersonIcon from "@mui/icons-material/Person"; // Неавторизованный
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ConDialogLogin from "../ConDialogLogin/ConDialogLogin";
import UserDataDrawer from "../UserDataDrawer/UserDataDrawer";

const ConHeader = ({ theme, userData }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [userDataView, setUserDataView] = useState(false);
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = () => {
    //setIsLogin(true);
    //setIsAuthenticated(!isAuthenticated);
    if (!userData.isAuthentificated) {
      setIsLogin(true);
    } else {
      setUserDataView(true);
    }
  };

  const onLoad = (e) => {
    setIsLoading(false);
  };

  const handleClose = () => {
    setIsLogin(false);
  };

  return (
    <>
      <AppBar className={styles.appbar}>
        <Container className={styles.container}>
          <Toolbar className={styles.toolbar}>
            <Typography variant="h5" className={styles.typography_h5}>
              Создавать договора - это просто!
            </Typography>
            <Box className={styles.box}>
              <Tooltip
                title={
                  userData.isAuthentificated
                    ? `${userData.first_name} ${userData.last_name}`
                    : "Вы не авторизованы"
                }
              >
                <IconButton onClick={handleClick}>
                  {userData.isAuthentificated ? (
                    <>
                      {isLoading && (
                        <Avatar
                          alt={`${userData.first_name} ${userData.last_name}`}
                          src="/broken-image.jpg"
                        />
                      )}
                      <Avatar
                        alt={`${userData.first_name} ${userData.last_name}`}
                        src={userData.avatar}
                        onLoad={onLoad}
                        style={{ display: isLoading ? "none" : "block" }}
                      />
                    </>
                  ) : (
                    <Avatar src="/broken-image.jpg" />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <UserDataDrawer
        userDataView={userDataView}
        setUserDataView={setUserDataView}
        userData={userData}
      />
      <ConDialogLogin isLogin={isLogin} handleClose={handleClose} />
    </>
  );
};

export default ConHeader;
