import React, { useState } from "react";
import {
  TextField,
  IconButton,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import LightToolTip from "../LightToolTip/LightToolTip.jsx";
import style from "./Fields.module.css";

const DynamicField = (props) => {
  const { id, label, innerFields, setValue, tooltip } = props;
  const idField = id;

  const initializeFields = (innerFields) => {
    return innerFields.reduce((acc, field) => {
      acc[field.id] = ""; // Инициализируем каждое поле пустой строкой
      return acc;
    }, {});
  };

  const [fields, setFields] = useState([]);

  const handleAddField = () => {
    setFields([
      ...fields,
      { id: Date.now(), ...initializeFields(innerFields) },
    ]);
  };

  const handleRemoveField = (id) => {
    setFields((prevFields) => {
      // Фильтруем поля, чтобы удалить комнату
      const updatedFields = prevFields.filter((field) => field.id !== id);

      // Удаляем соответствующее значение из state value
      setValue((prevValues) => {
        const { [idField]: removedValue, ...remainingValues } = prevValues; // Удаляем значение по id комнаты
        return remainingValues; // Возвращаем оставшиеся значения
      });
      return updatedFields; // Возвращаем обновленные поля
    });
  };

  const handleInputChange = (id, event) => {
    const { name, value } = event.target;

    // Обновляем состояние fields и одновременно обновляем значение
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field) =>
        field.id === id ? { ...field, [name]: value } : field
      );

      const newErrors = {};
      updatedFields.forEach((field) => {
        newErrors[field.id] = true;
      });

      // Теперь обновляем значение, используя обновленные поля
      setValue((prevValues) => ({
        ...prevValues,
        [idField]: updatedFields, // Обновляем по id
      }));

      return updatedFields; // Возвращаем обновленные поля для setFields
    });
  };

  return (
    <Box className={style.boxItemDynamic}>
      {fields.map((field) => (
        <Box
          key={field.id}
          display="flex"
          alignItems="center"
          marginBottom={2} // Используем MUI для отступа
        >
          {innerFields.map((innerField) => (
            <Box key={innerField.id} flex={1} marginRight={1}>
              {innerField.type === "text" && (
                <TextField
                  fullWidth
                  label={innerField.label}
                  name={innerField.id}
                  value={field[innerField.id] || ""}
                  inputMode={innerField.inputMode}
                  onChange={(event) => handleInputChange(field.id, event)}
                />
              )}
              {innerField.type === "select" && (
                <FormControl fullWidth>
                  <InputLabel>{innerField.label}</InputLabel>
                  <Select
                    label={innerField.label}
                    name={innerField.id}
                    value={field[innerField.id] || ""}
                    inputMode={innerField.inputMode}
                    onChange={(event) => handleInputChange(field.id, event)}
                    fullWidth
                  >
                    {innerField.data.map((select) => (
                      <MenuItem key={select.title} value={select.title}>
                        {select.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          ))}
          <IconButton
            color="secondary"
            onClick={() => handleRemoveField(field.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}

      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddField}
      >
        {label}
      </Button>
      {tooltip && <LightToolTip tooltip={tooltip} />}
    </Box>
  );
};

export default React.memo(DynamicField);
