import axios from "axios";
import ModalDialog from "../components/ModalDialog/ModalDialog";

const apiUrl = process.env.REACT_APP_PAYMENT_API;
const fullUrl = window.location.protocol + "//" + window.location.host;

const getPayments = async (orderId) => {
  try {
    const response = await axios.post(
      `${apiUrl}/create-payment`,
      {
        cost: "100.00",
        returnUrl: `${fullUrl}/payment?orderId=${orderId}`,
        orderId: orderId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const { confirmation_url } = response.data;
    window.location.href = confirmation_url;
  } catch (error) {
    ModalDialog("Ошибка формирования платежа", error.message, "error");
  }
};

export default getPayments;
