import React, { useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  DialogContent,
  Button,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./SignaturePad.css";
import saveSignature from "../../service/saveSignature.js";

const SignaturePad = ({ isOpen, hanldeClose }) => {
  const sigCanvas = useRef({});
  const [imageURL, setImageURL] = useState(null);
  const contentRef = useRef(null); // Реф для отслеживания размеров контейнера

  // Функция для сохранения подписи в формате PNG
  const saveSign = async () => {
    try {
      const canvas = sigCanvas.current.getTrimmedCanvas();
      const signatureData = canvas.toDataURL("image/png");
      // Удаляем заголовок data:image/png;base64, из строки
      const base64Data = signatureData.replace(/^data:image\/png;base64,/, "");
      await saveSignature(base64Data); // Сохранить изображение как URL
    } catch (error) {
      console.log(error);
    }
  };

  // Функция для очистки канвы
  const clearSignature = () => {
    sigCanvas.current.clear();
    setImageURL(null); // Очистить сохранённое изображение
  };

  return (
    <Dialog open={isOpen} onClose={hanldeClose} maxWidth="700px">
      <DialogTitle style={{ textAlign: "center" }}>
        Создайте свою подпись
        <IconButton
          aria-label="close"
          onClick={hanldeClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        ref={contentRef} // Привязываем реф к контейнеру для отслеживания размеров
        fullWidth
        sx={{
          paddingBottom: "10px",
          paddingTop: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%", // Занимает всю высоту родителя
          width: "100%", // Занимает всю ширину родителя
        }}
      >
        <SignatureCanvas
          ref={sigCanvas}
          penColor="#0D47A1"
          minWidth={2} // Минимальная толщина линии
          maxWidth={5} // Максимальная толщина линии
          canvasProps={{
            style: {
              width: "700px", // Растягиваем canvas на всю ширину
              height: "300px", // Растягиваем canvas на всю высоту
            },
            className: "signatureCanvas",
          }}
        />
        <Typography sx={{ marginTop: "10px" }}>
          Сделайте подпись, чтобы она вмещалась в окно выше и не была слишщком
          маленькой или большой
        </Typography>
      </DialogContent>

      <DialogActions sx={{ paddingRight: "24px" }}>
        <Button variant="outlined" onClick={saveSign}>
          Сохранить
        </Button>
        <Button variant="outlined" onClick={clearSignature}>
          Очистить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignaturePad;
