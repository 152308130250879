import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import requestGPT from "../service/requestGPT.js";

const GPT = () => {
  const [beharivorGpt, setBeharivorGpt] = useState("");
  const [requestGpt, setRequestGpt] = useState("");
  const [responseGpt, setResponseGpt] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const handeClick = async (e) => {
    const message = [
      {
        role: "system",
        text: beharivorGpt,
      },
      {
        role: "user",
        text: requestGpt,
      },
    ];
    try {
      setLoading(true);
      const request = await requestGPT(message);
      setLoading (false);
      setResponseGpt(request);
    }
    catch (error) {
      setLoading (false);
      console.log (error.message);
    }
  }
    

  return (
    <Container maxWidth="lm">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          mt: 5,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: "100vh",
        }}
      >
        <TextField
          label="Задать поведение модели"
          variant="outlined"
          fullWidth
          multiline
          rows={5}
          slotProps={{ htmlInput: { maxLength: 1000 } }}
          value={beharivorGpt}
          onChange={(e) => setBeharivorGpt(e.target.value)}
          helperText={`${beharivorGpt.length}/1000 символов`}
        />

        <TextField
          label="Запрос"
          variant="outlined"
          fullWidth
          multiline
          rows={5}
          value={requestGpt}
          slotProps={{ htmlInput: { maxLength: 1000 } }}
          onChange={(e) => setRequestGpt(e.target.value)}
          helperText={`${requestGpt.length}/1000 символов`}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handeClick}
        >
          Отправить
        </Button>
        <Box position="relative">
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
          <TextField
            multiline
            rows={11} // Вы можете установить желаемое количество строк
            variant="outlined"
            fullWidth
            value={responseGpt}
            InputProps={{
              style: { color: "#000" }, // Устанавливаем цвет текста черным
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default GPT;
