import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import TextFieldAll from "./TextFieldAll.jsx";
import styles from "./Fields.module.css";
import React, { useState, useEffect } from "react";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import style from "./Fields.module.css";
import LightToolTip from "../LightToolTip/LightToolTip.jsx";

const TextCheckBox = (props) => {

  const {
    id,
    isChecked,
    label,
    value,
    innerFields,
    isCheckedView,
    setValue,
    setError,
    addButton,
    tooltip,
    required,
    defaultValue
  } = props;
  const [fields, setFields] = useState([...innerFields]);
  const [isCheck, setIsCheck] = useState(isChecked);

  const handleChange = (id, event) => {
    const checked = event.target.checked;
    if (id === "zalog_rub") {
      !checked
        ? setValue((prevValues) => ({
              ...prevValues,
              [id]: prevValues["cost_rub"],
            }))
        : setValue((prevValues) => ({
            ...prevValues,
            [id]: defaultValue,
          }));
    }
    setIsCheck(checked);
    //Если ставим или убираем чекбокс при isCheckedView=true||false
    if ((!checked && isCheckedView) || (checked && !isCheckedView)) {
      if (!defaultValue) {
        setValue((prevValues) => {
          //если нет массива возврат из функции
          if (!Array.isArray(prevValues[id])) return { ...prevValues };
          // Если массив стал пустым, удаляем parentId полностью
          const updatedValues = { ...prevValues };
          delete updatedValues[id];

          return updatedValues; // Возвращаем обновленные значения
        });
      }
    }
  };

  const handleAddField = () => {
    const newField = {
      ...innerFields[0], // Берем свойства первого элемента
      id: Date.now(), // Устанавливаем уникальный id для нового объекта
      label: "Показатель", // Можно изменить другие свойства по необходимости
    };

    // Добавляем новый объект в массив
    setFields([...fields, newField]);
  };

  const handleRemoveField = (parentId, id) => {
    setFields((prevFields) => {
      // Фильтруем поля, чтобы удалить комнату
      const updatedFields = prevFields.filter((field) => field.id !== id);
      // Удаляем соответствующее значение из state value
      setValue((prevValues) => {
        //если нет массива возврат из функции
        if (!Array.isArray(prevValues[parentId])) return { ...prevValues };
        // Копируем массив, чтобы не изменять его напрямую
        const updatedArray = prevValues[parentId].filter(
          (item) => item.id !== id
        );

        // Если массив стал пустым, удаляем parentId полностью
        const updatedValues = updatedArray.length
          ? { ...prevValues, [parentId]: updatedArray }
          : { ...prevValues };

        // Удаляем parentId, если его больше нет
        if (updatedArray.length === 0) {
          delete updatedValues[parentId];
        }
        return updatedValues; // Возвращаем обновленные значения
      });
      return updatedFields; // Возвращаем обновленные поля */
    });
  };

  return (
    <FormControl fullWidth>
      <Box className={style.boxItem}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isCheck}
              onChange={(event) => handleChange(id, event)}
            />
          }
          label={label}
        />
        {tooltip && <LightToolTip tooltip={tooltip} />}
      </Box>
      {((isCheck && isCheckedView) || (!isCheck && !isCheckedView)) && (
        <Box>
          {fields.map((fields) => {
            const numberFormat =
              fields.type.includes("number") && fields.type.split("_")[1];
            return (
              <Box key={`Box_${fields.id}`} className={styles.box}>
                <TextFieldAll
                  {...(fields.parentId
                    ? { key: fields.id, id: fields.id }
                    : { key: id, id: id })}
                  //numberFormat={numberFormat}
                  value={
                    fields.parentId
                      ? Array.isArray(value)
                        ? value.find((item) => item.id === fields.id)?.value ||
                          ""
                        : ""
                      : value
                  }
                  parentId={fields.parentId}
                  label={fields.label}
                  setValue={setValue}
                  setError={setError}
                  required={required}
                  inputMode={fields.inputMode}
                />
                {fields.isDeleted && (
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      handleRemoveField(fields.parentId, fields.id)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            );
          })}
          {addButton && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddField}
            >
              {label}
            </Button>
          )}
        </Box>
      )}
    </FormControl>
  );
};

export default React.memo(TextCheckBox);
