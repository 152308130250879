import React, { useState } from "react";
import { Autocomplete, TextField, Box } from "@mui/material";
import { fetchAddressCode } from "../../service/getDadata.js";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/system";
import LightToolTip from "../LightToolTip/LightToolTip.jsx";
import style from "./Fields.module.css";

const AddressCode = (props) => {
  const [addressCode, setAddressCode] = useState([]);
  const {
    id,
    value,
    label,
    error,
    inputMode,
    setValue,
    setError,
    tooltip,
    required,
  } = props;

  const handleChange = (e, newValue) => {
    const value = e.target.value ? e.target.value : newValue;
    setValue((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
/*     setError((prevErrors) => ({
      ...prevErrors,
      [id]: value === "",
    })); */

    fetchAddressCode(value, setAddressCode);
  };

  const StyledPopper = styled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.modal, // Контролируем z-index, чтобы окно было выше других элементов
  }));

  const CustomPopper = (props) => {
    return (
      <StyledPopper
        {...props}
        placement="top-start" // Указываем что всплывающее окно всегда отображается сверху
        modifiers={[
          {
            name: "flip",
            enabled: false, // Отключаем переворот окна (он может по умолчанию переворачивать вниз)
          },
          {
            name: "preventOverflow",
            options: {
              altBoundary: true,
              tether: true, // Опция, чтобы окно оставалось на экране
            },
          },
        ]}
      />
    );
  };

  return (
    <Box className={style.boxItem}>
      <Autocomplete
        freeSolo
        options={addressCode}
        PopperComponent={CustomPopper}
        filterOptions={(x) => x}
        inputValue={value || ""}
        fullWidth
        onInputChange={(event, newValue) => handleChange(event, newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            inputMode={inputMode}
            {...(required && {
              error: !!error,
              helperText: !!error ? "Заполните поле" : "",
            })}
          />
        )}
      />
      {tooltip && <LightToolTip tooltip={tooltip} />}
    </Box>
  );
};

export default React.memo(AddressCode);
