import React, { useEffect, useState } from "react";
import checkPaymentStatus from "../service/checkPaymentStatus";
import { Box, Link, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PaymentStatus = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("orderId");
    checkPaymentStatus(setData, id);
  }, []);

  const handleReturnHome = () => {
    setData({});
    navigate("/");
  };

  const handleRefresh = () => {
    window.location.reload(); // Обновление страницы
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh", // Высота экрана
        padding: "20px",
        backgroundColor: "#f5f5f5", // Легкий фон для эстетики
      }}
    >
      {data.status === "succeeded" && (
        <>
          <Typography variant="h4" gutterBottom>
            Оплата прошла успешно!
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "20px" }}>
            Спасибо за покупку! Вы можете скачать ваш документ по ссылке ниже.
          </Typography>
          <Link
            href={data.document}
            download
            sx={{ textDecoration: "none", marginBottom: "20px" }}
          >
            <Button variant="contained" color="primary">
              Скачать документ
            </Button>
          </Link>
        </>
      )}
      {data.status === "pending" && (
        <>
          <Typography variant="h4" gutterBottom>
            Ваш платеж в ожидании
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleRefresh}
            sx={{ marginBottom: "10px" }}
          >
            Обновить
          </Button>
        </>
      )}
      {data.status === "canceled" && (
        <Typography variant="h4" gutterBottom>
          Платеж не удался. Пожалуйста, попробуйте снова.
        </Typography>
      )}
      <Button variant="outlined" color="secondary" onClick={handleReturnHome}>
        Вернуться на главную страницу
      </Button>
    </Box>
  );
};

export default PaymentStatus;
