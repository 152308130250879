export const Contractors = [
  {
    id: 1,
    name: "Договор аренды",
    data: [
      {
        block: "Укажите сведения о собственнике:",
        block_type: "landlord",
        data: [
          {
            id: "fiosobstvk",
            type: "text",
            label: "ФИО собственника",
            width: "300px",
            inputMode: "text",
            required: true,
          },
          {
            id: "paspsobs",
            type: "passport",
            label: "Паспорт собственника",
            width: "300px",
            inputMode: "numeric",
            required: true,
          },
          {
            id: "drsobs_date",
            type: "date",
            label: "Дата рождения собственника",
            width: "300px",
            inputMode: "decimal",
            required: true,
          },
          {
            id: "kemvydansob",
            type: "code",
            label: "Кем выдан паспорт",
            width: "300px",
            inputMode: "text",
            required: true,
          },
          {
            id: "datavydachisob_date",
            type: "date",
            label: "Дата выдачи паспорта",
            width: "300px",
            inputMode: "decimal",
            required: true,
          },
          {
            id: "adrsobstvk",
            type: "address",
            label: "Адрес регистрации собственника",
            width: "300px",
            inputMode: "text",
            required: true,
          },
          {
            id: "telsobs",
            type: "phone",
            label: "Номер телефона собственника",
            width: "300px",
            inputMode: "tel",
            required: false,
          },
        ],
      },
      {
        block: "Укажите сведения об арендаторе и проживающих:",
        block_type: "tenant",
        data: [
          {
            id: "fioarendtr",
            type: "text",
            label: "ФИО арендатора",
            width: "300px",
            inputMode: "text",
            required: true,
          },
          {
            id: "paspardtr",
            type: "passport",
            label: "Паспорт арендатора",
            width: "300px",
            inputMode: "numeric",
            required: true,
          },
          {
            id: "drarnd_date",
            type: "date",
            label: "Дата рождения арендатора",
            width: "300px",
            inputMode: "decimal",
            required: true,
          },
          {
            id: "kemvydanaren",
            type: "code",
            label: "Кем выдан паспорт",
            width: "300px",
            inputMode: "text",
            required: true,
          },
          {
            id: "datavydachiaren_date",
            type: "date",
            label: "Дата выдачи паспорта",
            width: "300px",
            inputMode: "decimal",
            required: true,
          },
          {
            id: "adrarendtr",
            type: "address",
            label: "Адрес регистрации арендатора",
            width: "300px",
            inputMode: "text",
            required: true,
          },
          {
            id: "telardtr",
            type: "phone",
            label: "Номер телефона арендатора",
            width: "300px",
            inputMode: "tel",
            required: false,
          },
          {
            id: "fiojilecs",
            type: "dynamic",
            label: "Добавить проживающего",
            width: "300px",
            required: false,
            innerFields: [
              {
                id: "fiozhilec",
                type: "text",
                label: "ФИО проживающего",
                inputMode: "text",
              },
            ],
          },
        ],
      },
      {
        block: "Укажите сведения о сдаваемом жилом помещении:",
        block_type: "landlord",
        data: [
          {
            id: "adres",
            type: "address",
            label: "Адрес объекта",
            width: "450px",
            inputMode: "text",
            required: true,
          },
          {
            id: "kadnomer",
            type: "text",
            label: "Кадастровый номер",
            tooltip:
              "Это поле требует заполнения в случае, если договор заключается более чем на 12 мес. и требуется его регистрация в Росреестр, если договор заключается менее 12 мес. оставьте данное поле пустым",
            width: "450px",
            inputMode: "text",
            required: false,
          },
        ],
      },
      {
        block:
          "Укажите сведения о цене и порядке оплаты, а так же общих условий договора:",
          block_type: "landlord",
        data: [
          {
            id: "gorod",
            type: "location",
            label: "Город заключения договора",
            width: "200px",
            inputMode: "text",
            required: true,
          },
          {
            id: "dogdata_date",
            type: "date",
            label: "Дата начала договора",
            defaultValue: "sysdate",
            width: "200px",
            inputMode: "decimal",
            required: true,
          },
          {
            id: "cost_rub",
            type: "number_7",
            label: "Стоимость аренды (руб.)",
            referenceValue: "zalog_rub",
            width: "250px",
            inputMode: "numeric",
            required: true,
          },
          {
            id: "dataplat",
            type: "number_2",
            label: "День ежемесячного платежа",
            width: "200px",
            defaultValue: 10,
            inputMode: "numeric",
            required: true,
          },
          {
            id: "zalog_rub",
            type: "checkbox",
            label: "Без залога",
            width: "200px",
            isChecked: false,
            isCheckedView: false,
            required: false,
            defaultValue: "Без залога",
            tooltip:
              "Рекомендуем указывать залог это позволит вам в случае порчи имущества вычесть эту сумму из залога. По истечению срока аренды объекта, залог необходимо вернуть Арендатору.",
            innerFields: [
              {
                id: "zalog",
                type: "text",
                inputMode: "number",
                label: "Укажите размер залога",
              },
            ],
          },
          {
            id: "srokdog_mon",
            type: "number_2",
            label: "Срок действия договора (в мес.)",
            width: "200px",
            defaultValue: "11",
            required: true,
            tooltip:
              " При сроке действия договора более 12 мес. вам потребуется его регистрация в Россреестр. Рекомендуем указывать срок 11 мес. и своевременно продлять его",
          },
          {
            id: "srokrastor_day",
            type: "number_2",
            label: "Срок предупреждения о досрочном расторжении (дни)",
            width: "200px",
            defaultValue: "30",
            required: true,
          },
          {
            id: "add_conditions",
            type: "select-checkboxes",
            label: "Дополнительные условия",
            width: "250px",
            required: false,
            tooltip:
              "Не рекомендуем разрешать сдачу квартиры в субаренду это позволит Арендатору сдавать объект третьим лицам.",
            data: [
              { id: "isAnimals", title: "Разрешено проживание с животными" },
              { id: "isSmoking", title: "Разрешено курение в квартире" },
              {
                id: "isaArenda",
                title: "Разрещена сдача квартиры в субаренду",
              },
            ],
          },
          {
            id: "kommunalka_parent",
            type: "select",
            label: "Коммунальные платежи",
            width: "250px",
            selectedId: "Иное (вписать свой вариант)",
            required: true,
            tooltip:
              "Условия при которых собственник оплачивает  расходы на содержание квартиры, а арендатор оплачивает свет, воду и т.д. по индвидуальным приборам учета, являются стандартными. Рекомендуем указывать именно их. Если у вас не установлены индивидуальные приборы учета, можете выбрать другой вариант или указать свой",
            defaultValue:
              "Расходы на содержание квартиры - оплачивает Собственник, свет, вода и т.д. - Арендатор в соответствии с показаниями приборов учета",
            data: [
              {
                title:
                  "Расходы на содержание квартиры - оплачивает Собственник, свет, вода и т.д. - Арендатор в соответствии с показаниями приборов учета",
              },
              { title: "Оплачиваются собственником" },
              { title: "Иное (вписать свой вариант)" },
            ],
            innerFields: [
              {
                id: "kommunalka",
                parentId: "kommunalka_parent",
                type: "text",
                label: "Впишите свой вариант",
              },
            ],
          },
        ],
      },
      {
        block: "Указать сведения об имуществе:",
        block_type: "landlord",
        data: [
          {
            id: "imushestvo",
            type: "dynamic",
            label: "Добавить имущество",
            tooltip:
              "Вы можете не указывать имущество, но в случае его порчи со стороны Арендатора, вам будет трудно доказать, что ваше  имущество было испорчено по вине Арендатора и вы скорее всего не сможете взыскать с него неустойку",
            width: "300px",
            required: false,
            innerFields: [
              {
                id: "item",
                type: "text",
                label: "Предмет",
                inputMode: "text",
              },
              {
                id: "sost",
                type: "select",
                label: "Состояние",
                data: [
                  { id: 1, title: "Новое - рабочее" },
                  { id: 2, title: "Б/у - рабочее" },
                  { id: 3, title: "Не рабочее" },
                ],
              },
            ],
          },
        ],
      },
      {
        block: "Указать значения индивидуальных приборов учета:",
        block_type: "landlord",
        data: [
          {
            id: "pokazateli",
            type: "checkbox",
            label: "Передать показания счетчиков",
            width: "200px",
            tooltip: "Рекомендуем вам передать показания счетчиков....",
            isChecked: false,
            isCheckedView: true,
            addButton: false,
            required: false,
            innerFields: [
              {
                id: "electr",
                type: "text",
                label: "Электричество (суммарно по всем тарифам кВт⋅ч)",
                inputMode: "number",
                isDeleted: true,
                parentId: "pokazateli",
              },
              {
                id: "hvs",
                type: "text",
                label: "Холодная вода (м3)",
                inputMode: "number",
                isDeleted: true,
                parentId: "pokazateli",
              },
              {
                id: "gvs",
                type: "text",
                label: "Горячая вода (м3)",
                inputMode: "number",
                isDeleted: true,
                parentId: "pokazateli",
              },
              {
                id: "gas",
                type: "text",
                label: "Газ",
                inputMode: "number (Гкал)",
                isDeleted: true,
                parentId: "pokazateli",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Договор купли продажи",
    data: [
      {
        id: "FIO",
        type: "text",
        label: "ФИО1",
        width: "400px",
      },
      {
        id: "PASSPORT",
        type: "passport",
        label: "Паспорт",
        width: "160px",
      },
      {
        id: "BIRTHDAY",
        type: "date",
        label: "Дата рождения",
        width: "200px",
      },
      {
        id: "START_DATE",
        type: "date",
        label: "Дата начала договора",
        width: "200px",
      },
      {
        id: "END_DATE",
        type: "date",
        label: "Дата окончания договора",
        width: "200px",
      },
    ],
  },
  {
    id: 3,
    name: "Расписка заемщика",
    data: [
      {
        id: "FIO",
        type: "text",
        label: "ФИО1",
        width: "400px",
      },
      {
        id: "PASSPORT",
        type: "passport",
        label: "Паспорт",
        width: "160px",
      },
      {
        id: "BIRTHDAY",
        type: "date",
        label: "Дата рождения",
        width: "200px",
      },
      {
        id: "START_DATE",
        type: "date",
        label: "Дата начала договора",
        width: "200px",
      },
      {
        id: "END_DATE",
        type: "date",
        label: "Дата окончания договора",
        width: "200px",
      },
    ],
  },
  {
    id: 4,
    name: "Договор займа",
    data: [
      {
        id: "FIO",
        type: "text",
        label: "ФИО1",
        width: "400px",
      },
      {
        id: "PASSPORT",
        type: "passport",
        label: "Паспорт",
        width: "160px",
      },
      {
        id: "BIRTHDAY",
        type: "date",
        label: "Дата рождения",
        width: "200px",
      },
      {
        id: "START_DATE",
        type: "date",
        label: "Дата начала договора",
        width: "200px",
      },
      {
        id: "END_DATE",
        type: "date",
        label: "Дата окончания договора",
        width: "200px",
      },
    ],
  },
  {
    id: 5,
    name: "Договор дарения",
    data: [
      {
        id: "FIO",
        type: "text",
        label: "ФИО1",
        width: "400px",
      },
      {
        id: "PASSPORT",
        type: "passport",
        label: "Паспорт",
        width: "160px",
      },
      {
        id: "BIRTHDAY",
        type: "date",
        label: "Дата рождения",
        width: "200px",
      },
      {
        id: "START_DATE",
        type: "date",
        label: "Дата начала договора",
        width: "200px",
      },
      {
        id: "END_DATE",
        type: "date",
        label: "Дата окончания договора",
        width: "200px",
      },
    ],
  },
  {
    id: 6,
    name: "Договор подряда",
    data: [
      {
        id: "PASSPORT",
        type: "passport",
        label: "Паспорт",
        width: "160px",
      },
      {
        id: "BIRTHDAY",
        type: "date",
        label: "Дата рождения",
        width: "200px",
      },
      {
        id: "START_DATE",
        type: "date",
        label: "Дата начала договора",
        width: "200px",
      },
      {
        id: "END_DATE",
        type: "date",
        label: "Дата окончания договора",
        width: "200px",
      },
    ],
  },
  {
    id: 7,
    name: "Договор поручительства",
    data: [
      {
        id: "FIO",
        type: "text",
        label: "ФИО1",
        width: "400px",
      },
      {
        id: "PASSPORT",
        type: "passport",
        label: "Паспорт",
        width: "160px",
      },
      {
        id: "BIRTHDAY",
        type: "date",
        label: "Дата рождения",
        width: "200px",
      },
      {
        id: "START_DATE",
        type: "date",
        label: "Дата начала договора",
        width: "200px",
      },
      {
        id: "END_DATE",
        type: "date",
        label: "Дата окончания договора",
        width: "200px",
      },
    ],
  },
];
