import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./Fields.module.css";
import LightToolTip from "../LightToolTip/LightToolTip.jsx";

const SelectField = (props) => {
  const {
    id,
    label,
    value,
    error,
    data,
    selectedId,
    defaultValue,
    setValue,
    setError,
    innerFields,
    tooltip,
    required,
  } = props;

  const [innerValue, setInnerValue] = useState();

  useEffect(() => {
    if (defaultValue) {
      setValue((prevValues) => ({
        ...prevValues,
        [id]: defaultValue,
      }));
    }
  }, [defaultValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    setValue((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const handleChangeInner = (id, event) => {
    const value = event.target.value;
    setInnerValue(value);
    setValue((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  return (
    <Box>
      <Box className={style.boxItem}>
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            value={value || ""}
            onChange={handleChange}
            fullWidth
            sx={{ overflow: "visible" }}
          >
            {data.map((select) => (
              <MenuItem key={select.title} value={select.title} fullWidth>
                {select.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box>{tooltip && <LightToolTip tooltip={tooltip} />}</Box>
      </Box>
      {value === selectedId &&
        innerFields.map((fields) => {
          console.log(innerValue);
          return (
            <Box key={`Box_${fields.id}`} fullWidth sx={{ marginTop: "20px" }}>
              <TextField
                key={fields.id}
                label={fields.label}
                type={fields.type}
                inputMode={fields.inputMode}
                variant="outlined"
                value={innerValue || ""}
                onChange={(event) => handleChangeInner(fields.id, event)}
                /*                 {...(required && {
                  error: !!error[fields.id],
                  helperText: !!error[fields.id] ? "Заполните поле" : "",
                })} */
                fullWidth
              />
            </Box>
          );
        })}
    </Box>
  );
};

export default SelectField;
