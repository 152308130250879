import { TextField, Box } from "@mui/material";
import style from "./Fields.module.css";
import LightToolTip from "../LightToolTip/LightToolTip.jsx";
import React from "react";

const TextFieldAll = (props) => {

  const {
    id,
    parentId,
    label,
    value,
    inputMode,
    error,
    setValue,
    setError,
    tooltip,
    required,
  } = props;

  const handleChange = (e, label) => {
    const value = e.target.value;
    if (!parentId) {
      setValue((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
      /*       setError((prevErrors) => ({
        ...prevErrors,
        [id]: value === "",
      })); */
    } else {
      setValue((prevValues) => {
        const newValues = { ...prevValues };
        // Если ключа parentId нет или это не массив, создаем его
        if (!Array.isArray(newValues[parentId])) {
          newValues[parentId] = [];
        } else {
          // Глубокое копирование массива показателей
          newValues[parentId] = [...newValues[parentId]];
        }

        // Находим индекс существующего элемента в массиве
        const existingItemIndex = newValues[parentId].findIndex(
          (item) => item.id === id
        );

        if (existingItemIndex !== -1) {
          // Копируем элемент, чтобы не мутировать исходный объект
          const updatedItem = {
            ...newValues[parentId][existingItemIndex],
            value,
          };

          // Обновляем массив показателей
          newValues[parentId][existingItemIndex] = updatedItem;
        } else {
          // Добавляем новый объект в массив, если элемент с таким id не существует
          newValues[parentId].push({ id, label, value });
        }

        return newValues; // Возвращаем обновленный объект
      });
    }
  };

  return (
    <Box className={style.boxItem}>
      <TextField
        key={id}
        label={label}
        type={inputMode}
        variant="outlined"
        value={value || ""}
        onChange={(e) => handleChange(e, label)}
        {...(required && {
          error: !!error,
          helperText: !!error ? "Заполните поле" : "",
        })}
        fullWidth
      />
      {tooltip && <LightToolTip tooltip={tooltip} />}
    </Box>
  );
};

export default React.memo(TextFieldAll);
