import React, { useState } from "react";
import { Box, Button, FormControl, FormLabel } from "@mui/material";
import ConFieldInput from "../ConFieldInput/СonFieldInput.js";
import CreateContract from "../CreateContract/CreateContract.js";
import styles from "./СonDataInput.module.css";
import ModalDialog from "../ModalDialog/ModalDialog.js";
import SignaturePad from "../SignaturePad/SignaturePad.jsx";

const ConDataInput = ({
  selectedContractData,
  inputValues,
  setInputValues,
  valuesError,
  setValuesError,
  handleSubmitValues,
  requestValues,
  loading,
}) => {
  const [loadingInput, setLoadingInput] = useState(false);
  const [isOpenSign, setIsOpenSign] = useState(false);

  const humanType = !requestValues.tenant && !requestValues.landlord ? "": requestValues.landlord ? "landlord" : "tenant";

  const openSign = (e) => {
    setIsOpenSign(true);
  };

  const handleClose = (e) => {
    setIsOpenSign(false);
  };

  try {
    return (
      <>
        {selectedContractData.data.map(
          (fields) =>
            (fields.block_type === humanType || !humanType) && (
              <FormControl
                key={fields.block}
                component="fieldset"
                className={styles.form_control}
              >
                <FormLabel component="legend" className={styles.form_label}>
                  {fields.block}
                </FormLabel>
                {fields.data.map((field) => (
                  <Box key={`Box_${field.id}`} className={styles.box}>
                    <ConFieldInput
                      key={`ConTextInput_${field.id}`}
                      id={field.id}
                      label={field.label}
                      label_checkbox={field.label_checkbox}
                      typeInput={field.type}
                      typeText={field.type_text}
                      valueWidth={field.width}
                      data={field.data}
                      selectedId={field.selectedId}
                      defaultValue={field.defaultValue}
                      innerFields={field.innerFields}
                      referenceValue={field.referenceValue}
                      tooltip={field.tooltip}
                      inputMode={field.inputMode}
                      isChecked={field.isChecked}
                      isCheckedView={field.isCheckedView}
                      addButton={field.addButton}
                      required={field.required}
                      value={inputValues}
                      setValue={setInputValues}
                      error={valuesError}
                      setError={setValuesError}
                      loadingInput={loadingInput}
                      setLoadingInput={setLoadingInput}
                      block={fields.block}
                    />
                  </Box>
                ))}
              </FormControl>
            )
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CreateContract
            handleSubmitValues={handleSubmitValues}
            loading={loading}
          />
          <Button
            variant="outlined"
            onClick={openSign}
            sx={{ marginLeft: "10px" }}
          >
            Поставить подпись
          </Button>
        </Box>
        <SignaturePad isOpen={isOpenSign} hanldeClose={handleClose} />
      </>
    );
  } catch (error) {
    ModalDialog("Ошибка выбора договора", error.message, "error");
  }
};

export default React.memo(ConDataInput);
