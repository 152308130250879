import {
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  DialogContent,
} from "@mui/material";
import VkOneButton from "../VkOneButton/VkOneButton";
import CloseIcon from "@mui/icons-material/Close";

const ConDialogLogin = ({ isLogin, handleClose }) => {
  return (
    <Dialog
      open={isLogin}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "400px", // Ширина
        },
      }}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        Войдите удобным способом
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{paddingBottom: "40px", paddingTop: "16px"}}>
        <VkOneButton />
      </DialogContent>
    </Dialog>
  );
};
export default ConDialogLogin;
