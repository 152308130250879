import axios from "axios";
import ModalDialog from "../components/ModalDialog/ModalDialog";

const apiUrl = process.env.REACT_APP_SERVICE_API;

const generatePKCE = async () => {
  try {
    const response = await axios.get(`${apiUrl}/generate-pkce`);
    return response.data;
  } catch (error) {
    ModalDialog("Ошибка при формировании документа", error.message, "error");
  }
};

export default generatePKCE;
